// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1PxDR_mJpsuWk8W7M9uJIDfFf3F_YpPM",
  authDomain: "glauroqj-8c48e.firebaseapp.com",
  projectId: "glauroqj-8c48e",
  storageBucket: "glauroqj-8c48e.appspot.com",
  messagingSenderId: "694392883215",
  appId: "1:694392883215:web:6fb76829d3800d26e57b7a",
  measurementId: "G-ZBPQ3FZM0R",
};

export const start = () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
};
